import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console. 
const firebaseConfig = {
  apiKey: "AIzaSyCdaogLz2Ykqk9JjlMyH33pwtFBh95kV0o",
  authDomain: "assi-a18d9.firebaseapp.com",
  projectId: "assi-a18d9",
  storageBucket: "assi-a18d9.appspot.com",
  messagingSenderId: "971751935725",
  appId: "1:971751935725:web:1ab9275abf55fe0160c6e4",
  measurementId: "G-H7PDGBE8TP"
  };

initializeApp(firebaseConfig);
const messaging = getMessaging();


export const requestForToken = () => {
    return getToken(messaging, { vapidKey: 'BEftcD-K5aICxDO_oKpCHxCz5lVudJTQ6sCZhQ7H6xgJvUe0NFb8E0PUbUoSqrjmEUX4FM6pZ1if9svIYJwCNFg' })
      .then((currentToken) => {
        if (currentToken) {
          return currentToken;
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };

  export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
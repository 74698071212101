import logo from './logo.svg';
import React,{useEffect,useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Cookies from 'js-cookie';
import Login from './Components/Login';
import Dashboard from './Components/Admin/Dashboard';
import Main_Category from './Components/Admin/MainCat';
import Sub_Category from './Components/Admin/SubCat';
import Accounts from './Components/Admin/Account';
import Rates from './Components/Admin/Rates';
import Car from './Components/Admin/Car';
import Bookings from './Components/Admin/Booking';

function App() {

    return (
      <Router>
        <Routes>
          <Route path="/" element={<Login className="Login_Container" />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/main_category" element={<Main_Category />} />
          <Route path="/sub_category" element={<Sub_Category />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/rates" element={<Rates />} />
          <Route path="/cars" element={<Car />} />
          <Route path="/bookings" element={<Bookings />} />
        </Routes>
      </Router>
    );

}

export default App;

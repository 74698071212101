import {React,useEffect, useState,Fragment} from 'react';
import ListItemMenu from '../Menu/menu';
import Navigation from '../Menu/Nav';
import Cookies from 'js-cookie';
import '../../App.css';
import { Badge, Box, Toolbar, Typography, Dialog, List,TextField, Button, IconButton,Container, Grid,InputLabel,FormControl,Alert } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Redirect from '../Redirect/Redirect';
import { GET_RATES_ENDPOINT,SUBCAT_ENDPOINT,ADD_RATES_ENDPOINT,RETRIEVE_CITIES_ENDPOINT,UPDATE_CITIES_ENDPOINT } from '../Endpoints';


const drawerWidth = 240;


const Rates = () => {

  const auth = Cookies.get('BookingToken');
  const [formData, setFormData] = useState({
    name:'',
    rates:'',
    catid:'',
    coupon_rate:''
  });
  const [datalist, setdatalist] = useState([]);
  const [rows, setRows] = useState([]);
  const [openPopup, setopenPopup] = useState(false);
  const [formTypes, setformTypes] = useState();
  const [editaccountID, seteditaccountID] = useState();
  const [checkValidate,setValidate] = useState({
    isvalidate:false
  });


  useEffect(() => {
    AllRates();
    AllSub();
     }, []);


  const handleClickOpen = () => {
    setformTypes(1);
    setFormData(()=>{
      return {
        name:'',
        rates:'',
        catid:''
      }
    })
    setopenPopup(true);
  }

  const handleEditClick = (id) =>{
      setformTypes(2);
      RetrieveRates(id);
      setopenPopup(true);
  }

  const handleChanges = (e) => {

    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

  };

  const handleClose = () => {
    setopenPopup(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createRateValidation();
    setTimeout(()=>{ 
      setValidate((prev)=>{
        return {
            isvalidate: false,
        }
      });
    },2000)
  }

  
 const createRateValidation = async () =>{

  if(formData.name === '' || formData.rates === '' || formData.catid === ''){
  
    setValidate((prev)=>{
        return {
            message:"Please enter fill all empty fields!",
            type:"warning",
            isvalidate: true,
            color:'error'
        }
     })
     return;
    }

    if (!/^\d+$/.test(formData.rates)) {

      setValidate((prev)=>{
          return {
              message:"Please enter a valid rates",
              type:"warning",
              isvalidate: true,
              color:'error'
          }
       })
       return;
    }



    formTypes === 1 ?  AddRates():UpdateRates();
 }


  const AddRates = async () => {
    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

     let bodyContent = new FormData();
     bodyContent.append("name",formData.name);
     bodyContent.append("rates",formData.rates);
     bodyContent.append("prov_id",formData.catid);
     bodyContent.append("c_rates",formData.coupon_rate);

     let response = await fetch(ADD_RATES_ENDPOINT(), { 
       method: "POST",
       body: bodyContent,
       headers: headersList
     });

     let data = await response.json();

     if(data.type == 1){

      setFormData(()=>{
          return {
            name:'',
            rates:'',
            catid:'',
            coupon_rate:''
          }
      })
      setValidate((prev)=>{
        return {
            message:"Record added successfully",
            type:"success",
            isvalidate: true,
            color:'success'
         }
      })

      AllSub();
      AllRates();

      setTimeout(()=>{ 
        setopenPopup(false);
        setValidate((prev)=>{
          return {
              isvalidate: false,
          }
        });
      },1000)

     }else{
      setValidate((prev)=>{
        return {
            message:data.message,
            type:"warning",
            isvalidate: true,
            color:'error'
        }
     });
     }

  }

  
  const RetrieveRates = async (id) => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

     let bodyContent = new FormData();
     bodyContent.append("id",id);

     let response = await fetch(RETRIEVE_CITIES_ENDPOINT(), { 
       method: "POST",
       body: bodyContent,
       headers: headersList
     });

     let data = await response.json();
     const {city_name,province_id,rate,coupon_rates} = data.message[0];

     if(data.type == 1){  
        setFormData(()=>{
            return {
              name:city_name,
              rates:rate,
              catid:province_id,
              coupon_rate: coupon_rates? coupon_rates:0
            }
        })
        
        seteditaccountID(id);
      }

   }


  const UpdateRates = async () => {
    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

     let bodyContent = new FormData();
     bodyContent.append("name",formData.name);
     bodyContent.append("rates",formData.rates);
     bodyContent.append("prov_id",formData.catid);
     bodyContent.append("c_rates",formData.coupon_rate);
     bodyContent.append("id",editaccountID);

     let response = await fetch(UPDATE_CITIES_ENDPOINT(), { 
       method: "POST",
       body: bodyContent,
       headers: headersList
     });

     let data = await response.json();

     if(data.type == 1){

      setFormData(()=>{
        return {
          name:'',
          rates:'',
          catid:'',
          coupon_rate:''
      }
      })
      setValidate((prev)=>{
        return {
            message:"Record updated successfully",
            type:"success",
            isvalidate: true,
            color:'success'
         }
      })
      AllSub();
      AllRates();
      setTimeout(()=>{ 
        setopenPopup(false);
        setValidate((prev)=>{
          return {
              isvalidate: false,
          }
        });
      },1000)

     }else{
      setValidate((prev)=>{
        return {
            message:data.message,
            type:"warning",
            isvalidate: true,
            color:'error'
        }
     });
     }

  }
  ;
  


  const AllRates =  async () => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

    let response = await fetch(GET_RATES_ENDPOINT(), { 
      method: "GET",
      headers: headersList
    });

    let data = await response.json();
    if(data.type == 1){  
      if (Array.isArray(data.message)) {
        setRows(data.message);
      } else {
        console.error('Invalid data format:', data.message);
      }
   }
   }

   const AllSub =  async () => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

    let response = await fetch(SUBCAT_ENDPOINT(), { 
      method: "GET",
      headers: headersList
    });

    let data = await response.json();
    if(data.type == 1){  
      if (Array.isArray(data.message)) {
        setdatalist(data.message);
      } else {
        console.error('Invalid data format:', data.message);
      }
    }
   }

   const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'city_name', headerName: 'Names', width: 250 },
    {
      field: 'rate',
      headerName: 'Rates',
      width: 250,
      renderCell: (params) => (
        <span>{params.value ? parseFloat(params.value).toLocaleString():0}</span>
      ),
    },
    {
      field: 'coupon_rates',
      headerName: 'Coupon Rates',
      width: 250,
      renderCell: (params) => (
        <span>{params.value ? parseFloat(params.value).toLocaleString():0}</span>
      ),
    },
    {
      field: 'prov_name',
      headerName: 'Category',
      width: 300,
      renderCell: (params) => (
        <Badge badgeContent={params.value} color="success" style={{ width: '100px' }} />
      ),
    },
    {
      field: 'editButton',
      headerName: 'Update',
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleEditClick(params.row.id)}
          color="error"
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];




  return (

    <Box sx={{ display: 'flex'}}>
      <Redirect path_name="rates"/>
      <Navigation page_name="Rates"/>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
      <Toolbar />
      <Button className='btn_open' variant="outlined" color="error" onClick={handleClickOpen} >
        <AddCircleIcon color='error' sx={{marginRight: '10px'}} /> Add Rates</Button>
          <div className='account_table'>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                sortModel: [
                {
                    field: 'name',
                    sort: 'asc',
                  },
                ],
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 20]}
            />
        </div>
        <Fragment>
    <Dialog
        className='add_account_popup'
        fullScreen
        open={openPopup}
        onClose={handleClose}
      >
          <Toolbar style={{background:'darkred'}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {formTypes === 1 ? 'Add Rates':'Update Rates'}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
                <CloseIcon />
            </Button>
          </Toolbar>
        <List>
        <Container maxWidth="md" className='input_styling'>
      <Typography variant="h5" align="center" gutterBottom>
         Rates Information
      </Typography>
      <form onSubmit={handleSubmit} >
        <Grid container spacing={2}>
        <Grid item xs={12}>
              {checkValidate.isvalidate &&
            <Alert severity={checkValidate.type} color={checkValidate.color}>
            {checkValidate.message}
            </Alert>}
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Name *"
              name="name"
              variant="filled"
              value={formData.name}
              onChange={handleChanges}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Rates *"
              name="rates"
              variant="filled"
              value={formData.rates}
              onChange={handleChanges}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Coupon Rates *"
              name="coupon_rate"
              variant="filled"
              value={formData.coupon_rate}
              onChange={handleChanges}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
          <FormControl variant="filled" sx={{width:"100%"}}>
            <InputLabel id="demo-simple-select-standard-label" sx={{color:'white'}}>Select Category *</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={formData.catid}
              onChange={handleChanges}
              label="cat"
              name="catid"
              className='form_custom'
            >
              <MenuItem value="">
              <em>Select Options</em>
              </MenuItem>
              { datalist.map((x) =><MenuItem key={x.id} value={x.id}>{x.province_name}</MenuItem>)}

            </Select>
          </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="outlined" color="error">
              {formTypes === 1 ? 'Add Rates':'Save Rates' }
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
        </List>
      </Dialog>
    </Fragment>
      </Box>
    </Box>)


}

export default Rates;
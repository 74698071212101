import DashboardIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';
import GradingIcon from '@mui/icons-material/Grading';
import SegmentIcon from '@mui/icons-material/Segment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';


const ListItemMenu = [
        {
          title: "Dashboard",
          path: "dashboard",
          icons: <DashboardIcon />
        },
        {
            title: "Accounts",
            path: "accounts",
            icons: <AssignmentIndIcon />
          },
          {
            title: "Bookings",
            path: "bookings",
            icons: <ReceiptIcon />
          },
          {
            title: "Cars",
            path: "cars",
            icons: <LocalTaxiIcon />
          },
          {
            title: "Main Category",
            path: "main_category",
            icons: <CategoryIcon />
          },
          {
            title: "Sub Category",
            path: "sub_category",
            icons: <SegmentIcon />
          },
          {
            title: "Rates",
            path: "rates",
            icons: <GradingIcon />
          }
]

export default ListItemMenu;
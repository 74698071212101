import {React,useEffect, useState,Fragment} from 'react';
import ListItemMenu from '../Menu/menu';
import Navigation from '../Menu/Nav';
import Cookies from 'js-cookie';
import '../../App.css';
import { Badge, Box, Toolbar, Typography, Dialog, List,TextField, Button, IconButton,Container, Grid,InputLabel,FormControl,Alert,Snackbar } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Redirect from '../Redirect/Redirect';
import { GET_BOOKINGS_ENDPOINT,AVAILABLE_DRIVERS_ENDPOINT,UPDATE_BOOKINGS_ENDPOINT } from '../Endpoints';
import io from 'socket.io-client';

const drawerWidth = 240;


const Bookings = () => {
  const auth = Cookies.get('BookingToken');
  const [formData, setFormData] = useState({
    account:'',
    amount: '',
    from: '',
    to: '',
    driver:'',
    status:'',

  });
  const [availableDriver, setavailableDriver] = useState([]);
  const [rows, setRows] = useState([]);
  const [openPopup, setopenPopup] = useState(false);
  const [formTypes, setformTypes] = useState();
  const [editaccountID, seteditaccountID] = useState();
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState();


  const [checkValidate,setValidate] = useState({
    isvalidate:false
  });

  useEffect(() => {
     Bookings();
     Available_Drivers();

     const socket = io('https://socket.assibooking.com/');

     socket.on('connect', () => {
       socket.on('update system', (message) => {
        
        Bookings();
         Available_Drivers();

         setOpen(true);
         setNotification('System is updating')
       });
     });
 
     socket.on('disconnect', () => {
       console.log('Disconnected from Socket.IO server');
     });


     }, []);


  const handleEditClick = (id,amount,terminal_name,city_name,account,status,driver) =>{
        setformTypes(2);

        setFormData(()=>{
          if(driver) {
            return {
              account:account,
              amount:amount,
              from:terminal_name,
              to:city_name,
              driver:driver,
              status:status,
            }
          }else{
            return {
              account:account,
              amount:amount,
              from:terminal_name,
              to:city_name,
              driver:'',
              status:status,
            }

          }


        })
        seteditaccountID(id);
        setopenPopup(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createBookingValidation();
    setTimeout(()=>{ 
      setValidate((prev)=>{
        return {
            isvalidate: false,
        }
      });
    },2000)
  }

  const handleChanges = (e) => {

    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

  };


  
  const createBookingValidation = async () =>{

    if(formData.status === ''){
    
      setValidate((prev)=>{
          return {
              message:"Please enter fill all empty fields!",
              type:"warning",
              isvalidate: true,
              color:'error'
          }
       })
       return;
      }

      UpdateBooking();
   }


   const UpdateBooking =  async () => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

     let bodyContent = new FormData();
     bodyContent.append("drivers",formData.driver);
     bodyContent.append("status",formData.status);
     bodyContent.append("id",editaccountID);

     let response = await fetch(UPDATE_BOOKINGS_ENDPOINT(), { 
       method: "POST",
       body: bodyContent,
       headers: headersList
     });

     let data = await response.json();

     if(data.type == 1){

      setFormData(()=>{
        return {
          account:'',
          amount:'',
          from:'',
          to:'',
          driver:'',
          status:'',
        }
      })
      setValidate((prev)=>{
        return {
            message:"Record updated successfully",
            type:"success",
            isvalidate: true,
            color:'success'
         }
      })

      Bookings();
      Available_Drivers();

      setTimeout(()=>{ 
        setopenPopup(false);
        setValidate((prev)=>{
          return {
              isvalidate: false,
          }
        });
      },1000)

     }else{
      setValidate((prev)=>{
        return {
            message:data.message,
            type:"warning",
            isvalidate: true,
            color:'error'
        }
     });
     }


   }
  



  const handleClose = () => {
    setopenPopup(false);
  }

  const closeupdate = () => {
    setOpen(false);
  };

  const Bookings =  async () => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

    let response = await fetch(GET_BOOKINGS_ENDPOINT(), { 
      method: "GET",
      headers: headersList
    });

    let data = await response.json();
    
    if(data.type == 1){
      if (Array.isArray(data.message)) {
        setRows(data.message);
      } else {
        console.error('Invalid data format:', data.message);
      }
    }

   }

   
  const Available_Drivers =  async () => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

    let response = await fetch(AVAILABLE_DRIVERS_ENDPOINT(), { 
      method: "GET",
      headers: headersList
    });

    let data = await response.json();
    
    if(data.type == 1){
      if (Array.isArray(data.message)) {
        setavailableDriver(data.message);
      } else {
        console.error('Invalid data format:', data.message);
      }
    }

   }


  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 30 },
    { field: 'terminal', headerName: 'From', width: 150 },
    { field: 'destination', headerName: 'To', width: 150 },
    { field: 'user', headerName: 'Account', width: 150 },
    { field: 'driver', headerName: 'Driver', width: 150 },
    { field: 'control_number', headerName: 'Control Number', width: 150 },
    { field: 'book_for', headerName: 'Booking for', width: 150 },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 100,
      renderCell: (params) => (
        <span>{parseFloat(params.value).toLocaleString()}</span>
      ),
    },
    { field: 'payment', headerName: 'Payment', width: 100,      
    renderCell: (params) => (
      <Badge badgeContent={params.value} color="success" style={{ width: '50px' }} />
    ), },
    { field: 'status', headerName: 'Status', width: 100,      
    renderCell: (params) => (
      <Badge badgeContent={params.value} color="warning" style={{ width: '50px' }} />
    ), },
    { field: 'created', headerName: 'Created Date', width: 150 },
    { field: 'completed', headerName: 'Completed Date', width: 150 },
    {
      field: 'editButton',
      headerName: 'Update',
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => (
          //id,amount,terminal_name,city_name,account,status,driver
        <IconButton
          onClick={() => handleEditClick(params.row.id,params.row.amount,params.row.terminal,params.row.destination,params.row.user,params.row.number_status,params.row.designated_driver)}
          color="error"
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];




  return (

    <Box sx={{ display: 'flex'}}>
      <Redirect path_name="bookings"/>
      <Navigation page_name="Bookings"/>
      <Snackbar open={open} autoHideDuration={5000} onClose={closeupdate}  anchorOrigin={{ vertical:'bottom', horizontal:'right' }}>
        <Alert onClose={closeupdate} severity="success">
            {notification}
        </Alert>
      </Snackbar>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
      <Toolbar />
      <div className='account_table'>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                sortModel: [
                {
                    field: 'id',
                    sort: 'desc',
                  },
                ],
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 20]}
            />
        </div>
        <Fragment>
    <Dialog
        className='add_account_popup'
        fullScreen
        open={openPopup}
        onClose={handleClose}
      >
          <Toolbar style={{background:'darkred'}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {formTypes === 1 ? 'Add Bookings':'Update Bookings'}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
                <CloseIcon />
            </Button>
          </Toolbar>
        <List>
        <Container maxWidth="md" className='input_styling'>
      <Typography variant="h5" align="center" gutterBottom>
         Booking Information
      </Typography>
      <form onSubmit={handleSubmit} >
        <Grid container spacing={2}>
        <Grid item xs={12}>
              {checkValidate.isvalidate &&
            <Alert severity={checkValidate.type} color={checkValidate.color}>
            {checkValidate.message}
            </Alert>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="From Read Only"
              name="from"
              variant="filled"
              value={formData.from}
              onChange={handleChanges}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="To Read Only"
              name="to"
              variant="filled"
              value={formData.to}
              onChange={handleChanges}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Account  Read Only"
              name="account"
              variant="filled"
              value={formData.account}
              onChange={handleChanges}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Amount Read Only"
              name="amount"
              variant="filled"
              value={formData.amount}
              onChange={handleChanges}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>


          <Grid item xs={12} sm={12}>
          <FormControl variant="filled" sx={{width:"100%"}}>
            <InputLabel id="demo-simple-select-standard-label" sx={{color:'white'}}>Select Status *</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={formData.status}
              onChange={handleChanges}
              label="Status"
              name="status"
              className='form_custom'
            >
              <MenuItem value="">
              <em>Select Options</em>
              </MenuItem>
              <MenuItem value={0}>Pending</MenuItem>
              <MenuItem value={1}>Approved</MenuItem>
              <MenuItem value={3}>Completed</MenuItem>
              <MenuItem value={2}>Cancel</MenuItem>
            </Select>
          </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
          <FormControl variant="filled" sx={{width:"100%"}}>
            <InputLabel id="demo-simple-select-standard-label" sx={{color:'white'}}>Available Drivers *</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={formData.driver}
              onChange={handleChanges}
              label="Driver"
              name="driver"
              className='form_custom'
            >
              <MenuItem value="">
              <em>Select Options</em>
              </MenuItem>
              { availableDriver.map((x) =><MenuItem key={x.driver_id} value={x.driver_id}>{x.control_number}</MenuItem>)}
            </Select>
          </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="outlined" color="error">
              {formTypes === 1 ? 'Add Bookings':'Save Bookings' }
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
        </List>
      </Dialog>
    </Fragment>
      </Box>
    </Box>)


}

export default Bookings;
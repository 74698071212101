import {React,useEffect, useState,Fragment} from 'react';
import ListItemMenu from '../Menu/menu';
import Navigation from '../Menu/Nav';
import Cookies from 'js-cookie';
import '../../App.css';
import { Badge, Box, Toolbar, Typography, Dialog, List,TextField, Button, IconButton,Container, Grid,InputLabel,FormControl,Alert } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {GET_MAINCAT_ENDPOINT,ADD_MAINCAT_ENDPOINT,UPDATE_MAINCAT_ENDPOINT,RETRIEVE_MAINCAT_ENDPOINT } from '../Endpoints';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Redirect from '../Redirect/Redirect';


const drawerWidth = 240;

const Main_Category = () => {

  const auth = Cookies.get('BookingToken');
  const [rows, setRows] = useState([]);
  const [openPopup, setopenPopup] = useState(false);
  const [formTypes, setformTypes] = useState();
  const [editaccountID, seteditaccountID] = useState();
  const [checkValidate,setValidate] = useState({
    isvalidate:false
  });
  const [formData, setFormData] = useState({
    catname: '',
    main_cat:''
  });
  

  useEffect(() => {
    AllMainCat();
     }, []);


   const AllMainCat =  async () => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

    let response = await fetch(GET_MAINCAT_ENDPOINT(), { 
      method: "GET",
      headers: headersList
    });

    let data = await response.json();
    
    if(data.type == 1){  
    if (Array.isArray(data.message)) {
      setRows(data.message);
    } else {
      console.error('Invalid data format:', data.message);
    }
    }

   }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditClick = (id)=>{
      setformTypes(2);
      RetrieveMainCategory(id);
      setopenPopup(true);
  }

  const handleClickOpen = () => {
      setformTypes(1);
      setFormData(()=>{
        return {
            catname: '',
            main_cat: '',
        }
      })
      setopenPopup(true);
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    createMainCatValidation();
    setTimeout(()=>{ 
      setValidate((prev)=>{
        return {
            isvalidate: false,
        }
      });
    },2000)
  }

  const handleClose = () => {
    setopenPopup(false);
  }

  const createMainCatValidation = () => {

    if(formData.name === '' || formData.main_cat === ''){
  
    setValidate((prev)=>{
        return {
            message:"Please enter fill all empty fields!",
            type:"warning",
            isvalidate: true,
            color:'error'
        }
     })
     return;
    }

    
    formTypes === 1 ?  AddMainCategory():UpdateMainCategory();

  }


   const AddMainCategory = async () =>{
    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

     let bodyContent = new FormData();
     bodyContent.append("name",formData.catname);
     bodyContent.append("main_cat",formData.main_cat);

     let response = await fetch(ADD_MAINCAT_ENDPOINT(), { 
       method: "POST",
       body: bodyContent,
       headers: headersList
     });

     let data = await response.json();

     if(data.type == 1){

      setFormData(()=>{
          return {
              catname: '',
              main_cat: '',
          }
      })
      setValidate((prev)=>{
        return {
            message:"Record added successfully",
            type:"success",
            isvalidate: true,
            color:'success'
         }
      })

      AllMainCat();
      setTimeout(()=>{ 
        setopenPopup(false);
        setValidate((prev)=>{
          return {
              isvalidate: false,
          }
        });
      },1000)

     }else{
      setValidate((prev)=>{
        return {
            message:data.message,
            type:"warning",
            isvalidate: true,
            color:'error'
        }
     });
     }
   }

  const RetrieveMainCategory =  async (id) =>{

      let headersList = {
        "Accept": "*/*",
        "Authorization": "Bearer "+auth
       }
       let bodyContent = new FormData();
       bodyContent.append("id",id);
  
       let response = await fetch(RETRIEVE_MAINCAT_ENDPOINT(), { 
         method: "POST",
         body: bodyContent,
         headers: headersList
       });
  
       let data = await response.json();
       const {name,region_id} = data.message[0];

       if(data.type == 1){  
          setFormData(()=>{
              return {
                  catname: name,
                  main_cat:region_id,
              }
          })
          
          seteditaccountID(id);
        }

   }


   const UpdateMainCategory = async () =>{
    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

     let bodyContent = new FormData();
     bodyContent.append("name",formData.catname);
     bodyContent.append("main_cat",formData.main_cat);
     bodyContent.append("id",editaccountID);

     let response = await fetch(UPDATE_MAINCAT_ENDPOINT(), { 
       method: "POST",
       body: bodyContent,
       headers: headersList
     });

     let data = await response.json();

     if(data.type == 1){
        
      setValidate((prev)=>{
        return {
            message:"Record update successfully",
            type:"success",
            isvalidate: true,
            color:'success'
        }
     })

     AllMainCat();
     setTimeout(()=>{ 
      setopenPopup(false);
      setValidate((prev)=>{
        return {
            isvalidate: false,
        }
      });
    },1000)
     
    }else{
      setValidate((prev)=>{
        return {
            message:data.message,
            type:"warning",
            isvalidate: true,
            color:'error'
        }
     });
    }
   }


  


  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 300 },
    { field: 'name', headerName: 'Names', width: 300 },
    { field: 'main_name', headerName: 'Category', width: 250, renderCell: (params) => (
      <Badge badgeContent= {params.value} color="success" style={{width: '20px'}} />
    ) },
    {
      field: 'editButton',
      headerName: 'Update',
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleEditClick(params.row.id)} 
          color="error" 
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];



  return (
    <Box sx={{ display: 'flex'}}>
      <Redirect path_name="main_category"/>
      <Navigation page_name="Main Category"/>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
      <Toolbar />
     <Button className='btn_open' variant="outlined" color="error" onClick={handleClickOpen} >
     <AddCircleIcon color='error' sx={{marginRight: '10px'}} /> Add Main Category</Button>
      <div className='account_table'>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            sortModel: [
            {
                field: 'name',
                sort: 'asc',
              },
            ],
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20]}
        />
      </div>
      <Fragment>
    <Dialog
        className='add_account_popup'
        fullScreen
        open={openPopup}
        onClose={handleClose}
      >
          <Toolbar style={{background:'darkred'}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {formTypes === 1 ? 'Add Main Category':'Update Main Category'}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
                <CloseIcon />
            </Button>
          </Toolbar>
        <List>
        <Container maxWidth="md" className='input_styling'>
      <Typography variant="h5" align="center" gutterBottom>
         Main Category Information
      </Typography>
      <form onSubmit={handleSubmit} >
        <Grid container spacing={2}>
        <Grid item xs={12}>
              {checkValidate.isvalidate &&
            <Alert severity={checkValidate.type} color={checkValidate.color}>
            {checkValidate.message}
            </Alert>}
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Name *"
              name="catname"
              variant="filled"
              value={formData.catname}
              onChange={handleChange}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
          <FormControl variant="filled" sx={{width:"100%"}}>
            <InputLabel id="demo-simple-select-standard-label" sx={{color:'white'}}>Select  Main Category *</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={formData.main_cat}
              onChange={handleChange}
              label="main_cat"
              name="main_cat"
              className='form_custom'
            >
              <MenuItem value="">
              <em>Select Options</em>
              </MenuItem>
              <MenuItem value={1}>NCR</MenuItem>
              <MenuItem value={2}>LUZON</MenuItem>
            </Select>
          </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="outlined" color="error">
              {formTypes === 1 ? 'Add Main Category':'Save Main Category' }
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
        </List>
      </Dialog>
    </Fragment>
       
      </Box>
    </Box>)


}

export default Main_Category;
import React from "react";
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import './../../App.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import Person4Icon from '@mui/icons-material/Person4';

const AdminCard = (props) => {


     const iconMap = {
        1: <AccountCircleIcon style={{ color: 'white' }} />,
        2: <AssignmentTurnedInIcon style={{ color: 'white' }} />,
        3: <Person4Icon style={{ color: 'white' }} />,
        4: <DriveEtaIcon style={{ color: 'white' }} />,
      };


        return (
            <Card className='card_main_container'>
            <CardContent style={{ display: 'flex', alignItems: 'center',padding:10 }}>
              {/* Icon on the left */}
              <Box
                sx={{
                  backgroundColor: 'darkred',
                  borderRadius: '8px', 
                  padding: '8px',
                }}
              >
                <IconButton>
                 {iconMap[props.card_type]}
                </IconButton>
              </Box>
              <Typography variant="body1" component="div" style={{ marginLeft: '20px' }}>
               <div className='card_heading'>{props.name}</div>
               <div className='card_sub_heading'>{props.total}</div>
              </Typography>
            </CardContent>
            </Card>
        )
}

export default AdminCard;
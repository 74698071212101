import React, { useState,useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const  Redirect = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        //const session = Cookies.get('BookingToken');
        const session = Cookies.get('BookingToken');
        if (session) {
            navigate('/'+props.path_name);
        } else {
            navigate('/');
    
        }
      }, []);
}

export default Redirect;


import * as React from 'react';
import Navigation from '../Menu/Nav';
import Redirect from '../Redirect/Redirect';
import { requestForToken, onMessageListener } from '../Notification/firebase'
import {CREATE_TOKEN_ENDPOINT,CREATE_CARDS_ENDPOINT,MONTLY_TRANSACTION_ENDPOINT,ALL_USERS_ENDPOINT, TERMINALS_TOTAL_TRANSACTION_ENDPOINT} from '../Endpoints';
import {Box,Toolbar,Grid,Snackbar,Alert,} from '@mui/material';
import AdminCard from '../DashboardComponent/AdminCard';
import { Chart as ChartJS } from 'chart.js/auto';
import { Bar,Line,Doughnut } from 'react-chartjs-2';
import {BarOptions,DoughnutOptions,LineOptions } from '../DashboardComponent/ChartData';
import './../../App.css';
import Cookies from 'js-cookie';
import io from 'socket.io-client';



const drawerWidth = 240;

const Dashboard = () => {

  const [token,setToken] = React.useState();
  const [notification, setNotification] = React.useState();

  const [open, setOpen] = React.useState(false);
  const [monthlyUserData, setMonthlyUserData] = React.useState({});
  const [monthlyTransactionData, setMonthlyTransactionData] = React.useState({});
  const [monthlyTermData, setMonthlyTermData] = React.useState({});

  const [Cards_Data,setCardData] = React.useState({
        total_account:0,
        total_drivers:0,
        total_cars:0,
        total_books:0,
  });

  const notify = () => Get_Cards();


  React.useEffect(() => {
    Get_Cards();

    const socket = io('https://socket.assibooking.com/');

    socket.on('connect', () => {
      socket.on('update system', (message) => {
        notify();
        setOpen(true);
        setNotification('System is updating')
      });
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from Socket.IO server');
    });


  }, []);


  const OnNoficiation =  async () => {
    const notif =  await requestForToken();
    if(notif){

      const push = Cookies.get('Push') ? 1:0;
      const auth = Cookies.get('BookingToken');
      const acc_id = Cookies.get('uid');

      let headersList = {
        "Accept": "*/*",
        "Authorization": "Bearer "+auth
       }

      let bodyContent = new FormData();
      bodyContent.append("token",notif );
      bodyContent.append("account_id",acc_id);
      bodyContent.append("push", push);
      
      let response = await fetch(CREATE_TOKEN_ENDPOINT(), { 
        method: "POST",
        body: bodyContent,
        headers: headersList
      });
      
      let data = await response.json();

      const currentPushSetting = Cookies.get('Push') === 'true';
      const expiresOption = { expires: 10 };
      
      Cookies.set('Push', currentPushSetting, expiresOption);
      const newPushSetting = !currentPushSetting;
      Cookies.set('Push', newPushSetting, expiresOption);

      newPushSetting ? setNotification('Push Notification Is Activated!'): setNotification('Push Notification Is Not Activated!');
      
      setOpen(true);

    }else{
      setOpen(true);
      setNotification('Default Browser Not Supported By Push!');
    }  
  }



  const Get_Cards =  async () => {
      const auth = Cookies.get('BookingToken');
      
      let headersList = {
        "Accept": "*/*",
        "Authorization": "Bearer "+auth
       }

      let response = await fetch(CREATE_CARDS_ENDPOINT()+'1', { 
        method: "GET",
        headers: headersList
      });

      let mtd_transaction = await fetch(MONTLY_TRANSACTION_ENDPOINT(), { 
        method: "GET",
        headers: headersList
      });

      let mtd_all_users = await fetch(ALL_USERS_ENDPOINT(), { 
        method: "GET",
        headers: headersList
      });

      let mtd_terminals = await fetch(TERMINALS_TOTAL_TRANSACTION_ENDPOINT(), { 
        method: "GET",
        headers: headersList
      });

      let data = await response.json();
      let allusers = await mtd_all_users.json();
      let allterminals = await mtd_terminals.json();
      let alltransactions = await mtd_transaction.json();


      setMonthlyUserData(()=>{
        return {
          users: allusers.message.Users,
          drivers: allusers.message.Drivers,
          admins: allusers.message.Admins,
        }
      })

      setMonthlyTransactionData(alltransactions.message);
      setMonthlyTermData(allterminals.message);

      setCardData(()=>{
          return {
             total_account:data.message.total_account,
             total_drivers:data.message.total_drivers,
             total_cars:data.message.total_cars,
             total_books:data.message.total_booking,
          }
      })
   }

   
/*
   onMessageListener()
   .then((payload) => {
     setNotification('Notification Receive!');
     setOpen(true);
     Get_Cards();
   })
   .catch((err) => console.log('failed: ', err));
*/

   const handleClose = () => {
    setOpen(false);
  };

const titles = monthlyTransactionData && Array.isArray(monthlyTransactionData)
  ? monthlyTransactionData.map(item => item.label)
  : [];

const dataValues = monthlyTransactionData && Array.isArray(monthlyTransactionData)
  ? monthlyTransactionData.map(item => item.amount)
  : [];


  const bartitles = monthlyTermData && Array.isArray(monthlyTermData)
  ? monthlyTermData.map(item => item.label)
  : [];

const termVal = monthlyTermData && Array.isArray(monthlyTermData)
  ? monthlyTermData.map(item => item.total)
  : [];



   
  return (
    <Box sx={{
      display: 'flex',
      background: '#222',
      height: '100vh',
      overflowY: 'scroll',
      scrollbarWidth: 'thin',  // For Firefox
      scrollbarColor: '#333 #222',  // For Firefox
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#333',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#222',
      },
    }}>
    
      <Redirect path_name="dashboard"/>
      <Navigation page_name="Dashboard" onSwitch={OnNoficiation} />

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}  anchorOrigin={{ vertical:'bottom', horizontal:'right' }}>
        <Alert onClose={handleClose} severity="success">
            {notification}
        </Alert>
      </Snackbar>

      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
      <Toolbar />

      <Grid container spacing={2}>

        <Grid item xs={12} lg={3}>
          <AdminCard name="Total Accounts" card_type="1" total={Cards_Data.total_account} />
        </Grid>
        <Grid item xs={12}  lg={3}>
        <AdminCard name="Total Bookings" card_type="2" total={Cards_Data.total_books}  />
        </Grid>
        <Grid item xs={12}  lg={3}>
        <AdminCard name="Total Drivers" card_type="3"  total={Cards_Data.total_drivers} />
        </Grid>
        <Grid item xs={12}  lg={3}>
        <AdminCard name="Total Cars" card_type="4" total={Cards_Data.total_cars}  />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{marginTop:'10px'}}>

        <Grid item xs={12} lg={6}>
        <Box style={{background:'#333',padding:'10px',width:'100%',height:'300px'}}>
          <Bar 
            data={{labels: bartitles,
            datasets:[
                  {
                  label: "Amount",
                  data:termVal,
                  backgroundColor:['darkred']
                  }

                  ]
            }}
            options={BarOptions}
           />
        </Box>
        </Grid>

        <Grid item xs={12}  lg={6}>
        <Box style={{background:'#333',padding:'10px',width:'100%',height:'300px'}}>
          <Doughnut 
            data={{labels: ['Users','Drivers','Admins'],
            datasets:[
                  {
                  data:[monthlyUserData.users,monthlyUserData.drivers,monthlyUserData.admins],
                  backgroundColor:['darkred','white','black']
                  }
                  
                  ]
            }}
            options={DoughnutOptions}
           />
          </Box>
        </Grid>

        <Grid item xs={12}  lg={12}>
        <Box style={{background:'#333',padding:'10px',width:'100%',height:'350px'}}>
          <Line 
            data={{labels:titles,
            datasets:[
                  {
                  data:dataValues,
                  backgroundColor:['darkred'],
                  fill: false,
                  borderColor: 'rgb(255, 255, 255)',
                  }
                  
                  ]
            }}
            options={LineOptions}
           />
          </Box>
        </Grid>
        </Grid>
      </Box>
    </Box>)


}

export default Dashboard;
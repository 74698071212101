const Base_URL = "https://api.assibooking.com/booking/";

const LOGIN_ENDPOINT = () =>{
      return Base_URL+'auth/';
}

const CREATE_TOKEN_ENDPOINT = () => {
      return Base_URL+'create_token/';
}

const CREATE_CARDS_ENDPOINT = () => {
      return Base_URL+'cards/';
}

const MONTLY_TRANSACTION_ENDPOINT = () => {
      return Base_URL+'monthly_transactions/';
}


const ALL_USERS_ENDPOINT = () => {
      return Base_URL+'monthly_users/';
}


const TERMINALS_TOTAL_TRANSACTION_ENDPOINT = () => {
      return Base_URL+'monthly_terminal_transactions/';
}

const GET_ACCOUNTS_ENDPOINT = () => {
  return Base_URL+'accounts/';
}

const CREATE_ACCOUNTS_ENDPOINT = () => {
      return Base_URL+'create_account/';

}

const RETRIEVE_ACCOUNTS_ENDPOINT = () => {
      return Base_URL+'retrieve_account/';

}
    

const UPDATE_ACCOUNTS_ENDPOINT = () => {
      return Base_URL+'updates_account/';

}

const GET_MAINCAT_ENDPOINT = () => {
      return Base_URL+'main_cat/';

}
    

const ADD_MAINCAT_ENDPOINT = () => {
      return Base_URL+'add_main_cat/';
}
    

const UPDATE_MAINCAT_ENDPOINT = () => {
      return Base_URL+'update_main_cat/';
}
    

const RETRIEVE_MAINCAT_ENDPOINT = () => {
      return Base_URL+'retrieve_main_cat/';
}
    

const SUBCAT_ENDPOINT = () => {
      return Base_URL+'sub_cat/';
}
    

const RETRIEVE_SUBCAT_ENDPOINT = () => {

      return Base_URL+'retrieve_sub_cat/';
}
    
const ADD_SUBCAT_ENDPOINT = () => {

      return Base_URL+'add_sub_cat/';
}
    
const UPDATE_SUBCAT_ENDPOINT = () => {

      return Base_URL+'update_sub_cat/';
}
    
const GET_RATES_ENDPOINT = () => {

      return Base_URL+'get_cities/';
}

const ADD_RATES_ENDPOINT = () => {

    return Base_URL+'add_cities/';
}
    

const RETRIEVE_CITIES_ENDPOINT= () => {
return Base_URL+'retrieve_cities/';
}


const UPDATE_CITIES_ENDPOINT= () => {
      return Base_URL+'update_cities/';
 }
      

 
const GET_CARS_ENDPOINT= () => {
      return Base_URL+'get_cars/';
 }
      
 const GET_DRIVERS_ENDPOINT= () => {
      return Base_URL+'all_drivers/';
 }

 const ADD_CARS_ENDPOINT = () => {
      return Base_URL+'add_cars/';
 }

const UPDATE_CARS_ENDPOINT = () => {
      return Base_URL+'update_cars/';
}

const RETRIEVE_CAR_ENDPOINT = () => {
      return Base_URL+'retrieve_cars/';
}

const GET_DRIVERSBYID_ENDPOINT = () => {
 return Base_URL+'retrieve_drivers/';
}

const GET_BOOKINGS_ENDPOINT = () => {
      return Base_URL+'transactions/';
}

const GET_TERMINALS_ENDPOINT = () => {
      return Base_URL+'terminals/';
}

const AVAILABLE_DRIVERS_ENDPOINT = () => {
      return Base_URL+'available_drivers/';
}

const UPDATE_BOOKINGS_ENDPOINT = () => {
      return Base_URL+'update_bookings/';

}


export { LOGIN_ENDPOINT,CREATE_TOKEN_ENDPOINT,CREATE_CARDS_ENDPOINT,MONTLY_TRANSACTION_ENDPOINT,ALL_USERS_ENDPOINT, TERMINALS_TOTAL_TRANSACTION_ENDPOINT,GET_ACCOUNTS_ENDPOINT,CREATE_ACCOUNTS_ENDPOINT,RETRIEVE_ACCOUNTS_ENDPOINT,UPDATE_ACCOUNTS_ENDPOINT,GET_MAINCAT_ENDPOINT,ADD_MAINCAT_ENDPOINT,UPDATE_MAINCAT_ENDPOINT,RETRIEVE_MAINCAT_ENDPOINT,SUBCAT_ENDPOINT,RETRIEVE_SUBCAT_ENDPOINT,ADD_SUBCAT_ENDPOINT,UPDATE_SUBCAT_ENDPOINT,GET_RATES_ENDPOINT,ADD_RATES_ENDPOINT,RETRIEVE_CITIES_ENDPOINT,UPDATE_CITIES_ENDPOINT,GET_CARS_ENDPOINT,GET_DRIVERS_ENDPOINT,ADD_CARS_ENDPOINT,UPDATE_CARS_ENDPOINT,RETRIEVE_CAR_ENDPOINT,GET_DRIVERSBYID_ENDPOINT,GET_BOOKINGS_ENDPOINT,GET_TERMINALS_ENDPOINT,AVAILABLE_DRIVERS_ENDPOINT,UPDATE_BOOKINGS_ENDPOINT}
import {React,useEffect, useState,Fragment} from 'react';
import ListItemMenu from '../Menu/menu';
import Navigation from '../Menu/Nav';
import Cookies from 'js-cookie';
import '../../App.css';
import { Badge, Box, Toolbar, Typography, Dialog, List,TextField, Button, IconButton,Container, Grid,InputLabel,FormControl,Alert } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Redirect from '../Redirect/Redirect';
import { GET_CARS_ENDPOINT,GET_DRIVERS_ENDPOINT,ADD_CARS_ENDPOINT,UPDATE_CARS_ENDPOINT,RETRIEVE_CAR_ENDPOINT,GET_DRIVERSBYID_ENDPOINT } from '../Endpoints';

const drawerWidth = 240;


const Car = () => {
  const auth = Cookies.get('BookingToken');
  const [formData, setFormData] = useState({
    car_number:'',
    car_model:'',
    drivers:'',
    isActive:'',
    ctr_num:''

  });
  const [datalist, setdatalist] = useState([]);
  const [rows, setRows] = useState([]);
  const [openPopup, setopenPopup] = useState(false);
  const [formTypes, setformTypes] = useState();
  const [editaccountID, seteditaccountID] = useState();
  const [desID, setdesID] = useState();
  const [checkValidate,setValidate] = useState({
    isvalidate:false
  });

  
  useEffect(() => {
    AllCars();
    AllDrivers();
     }, []);



  const handleEditClick = (id,desid) => {
      setformTypes(2);
      RetrieveCar(id);
      setopenPopup(true);
      setdesID(desid)
  }   

  const handleClickOpen = () => {
    setformTypes(1);
    setFormData(()=>{
      return {
        car_number:'',
        car_model:'',
        drivers:'',
        isActive:''
      }
    })
    setopenPopup(true);
  }

  const handleClose = () => {
    setopenPopup(false);
  }

  const handleChanges = (e) => {

    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createCarValidation();
    setTimeout(()=>{ 
      setValidate((prev)=>{
        return {
            isvalidate: false,
        }
      });
    },2000)
  }

  const createCarValidation = async () =>{

    if(formData.car_number === '' || formData.car_model === '' || formData.isActive === '' || formData.ctr_num ===''){
    
      setValidate((prev)=>{
          return {
              message:"Please enter fill all empty fields!",
              type:"warning",
              isvalidate: true,
              color:'error'
          }
       })
       return;
      }
  
  
      formTypes === 1 ?  AddCars():UpdateCars();
   }
  



  const AllCars =  async () => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

    let response = await fetch(GET_CARS_ENDPOINT(), { 
      method: "GET",
      headers: headersList
    });

    let data = await response.json();
    if(data.type == 1){
      if (Array.isArray(data.message)) {
        setRows(data.message);
      } else {
        console.error('Invalid data format:', data.message);
      }
    }

   }

   const AllDrivers =  async () => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

    let response = await fetch(GET_DRIVERS_ENDPOINT(), { 
      method: "GET",
      headers: headersList
    });

    let data = await response.json();
    
    if(data.type == 1){
      if (Array.isArray(data.message)) {
        setdatalist(data.message);
      } else {
        console.error('Invalid data format:', data.message);
      }
    }

   }

   
   const GETDrivers =  async (id) => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

    let response = await fetch(GET_DRIVERSBYID_ENDPOINT()+id, { 
      method: "GET",
      headers: headersList
    });

    let data = await response.json();
    
    if(data.type == 1){
      if (Array.isArray(data.message)) {
        setdatalist(data.message);
      } else {
        console.error('Invalid data format:', data.message);
      }
    }
   }


   const AddCars = async () => {

      let headersList = {
        "Accept": "*/*",
        "Authorization": "Bearer "+auth
      }
      
     let bodyContent = new FormData();
     bodyContent.append("car_number",formData.car_number);
     bodyContent.append("car_model",formData.car_model);
     bodyContent.append("ctr_number",formData.ctr_num);
     bodyContent.append("drivers",formData.drivers);
     bodyContent.append("isActive",formData.isActive);
     bodyContent.append("id",editaccountID);

     let response = await fetch(ADD_CARS_ENDPOINT(), { 
      method: "POST",
      body: bodyContent,
      headers: headersList
    });

    let data = await response.json();


    if(data.type == 1){

      setFormData(()=>{
          return {
            car_number:'',
            car_model:'',
            drivers:'',
            isActive:''
          }
      })
      setValidate((prev)=>{
        return {
            message:"Record added successfully",
            type:"success",
            isvalidate: true,
            color:'success'
         }
      })

      AllCars();
      AllDrivers();
      setTimeout(()=>{ 
        setopenPopup(false);
        setValidate((prev)=>{
          return {
              isvalidate: false,
          }
        });
      },1000)

     }else{
      setValidate((prev)=>{
        return {
            message:data.message,
            type:"warning",
            isvalidate: true,
            color:'error'
        }
     });
     }


   }

   const UpdateCars =  async () => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

     let bodyContent = new FormData();
     bodyContent.append("car_number",formData.car_number);
     bodyContent.append("car_model",formData.car_model);
     bodyContent.append("ctr_number",formData.ctr_num);
     bodyContent.append("drivers",formData.drivers);
     bodyContent.append("isActive",formData.isActive);
     bodyContent.append("id",editaccountID);
     bodyContent.append("desig_id",desID);
     

     let response = await fetch(UPDATE_CARS_ENDPOINT(), { 
       method: "POST",
       body: bodyContent,
       headers: headersList
     });

     let data = await response.json();

     if(data.type == 1){

      setFormData(()=>{
        return {
            car_number:'',
            car_model:'',
            drivers:'',
            isActive:'',
            ctr_num:''
       }
      })
      setValidate((prev)=>{
        return {
            message:"Record updated successfully",
            type:"success",
            isvalidate: true,
            color:'success'
         }
      })

      AllCars();
      AllDrivers();

      setTimeout(()=>{ 
        setopenPopup(false);
        setValidate((prev)=>{
          return {
              isvalidate: false,
          }
        });
      },1000)

     }else{
      setValidate((prev)=>{
        return {
            message:data.message,
            type:"warning",
            isvalidate: true,
            color:'error'
        }
     });
     }


   }


   const RetrieveCar = async (id) => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

     let bodyContent = new FormData();
     bodyContent.append("id",id);

     let response = await fetch(RETRIEVE_CAR_ENDPOINT(), { 
       method: "POST",
       body: bodyContent,
       headers: headersList
     });

     let data = await response.json();
     const {car_number,model,driver_id,isActive,control_number} = data.message[0];

     if(driver_id !== null){
        GETDrivers(driver_id);

        if(data.type == 1){  
          setFormData(()=>{
              return {
                car_number:car_number,
                car_model:model,
                drivers:driver_id,
                isActive:isActive,
                ctr_num:control_number
              }
          })
          
          seteditaccountID(id);
        }
     }else{
      GETDrivers(driver_id);

      if(data.type == 1){  
        setFormData(()=>{
            return {
              car_number:car_number,
              car_model:model,
              isActive:isActive,
              drivers:'',
              ctr_num:control_number
            }
        })
        seteditaccountID(id);
      }

     }

   }


   const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 30 },
    { field: 'model', headerName: 'Model', width: 250 },
    { field: 'control_number', headerName: 'Control Number', width: 250 },
    { field: 'plate_number', headerName: 'Plate', width: 100 },
    { field: 'full_name', headerName: 'Driver', width: 250 },
    { field: 'active_name', headerName: 'isActive', width: 100 },
    { field: 'status_name', headerName: 'Status', width: 100,      
    renderCell: (params) => (
      <Badge badgeContent={params.value} color="success" style={{ width: '50px' }} />
    ), },
    {
      field: 'editButton',
      headerName: 'Update',
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleEditClick(params.row.id,params.row.designated_id)}
          color="error"
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];




  return (

    <Box sx={{ display: 'flex'}}>
      <Redirect path_name="cars"/>
      <Navigation page_name="Cars"/>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
      <Toolbar />
      <Button className='btn_open' variant="outlined" color="error" onClick={handleClickOpen} >
        <AddCircleIcon color='error' sx={{marginRight: '10px'}} /> Add Cars</Button>
          <div className='account_table'>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                sortModel: [
                {
                    field: 'name',
                    sort: 'asc',
                  },
                ],
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 20]}
            />
        </div>
        <Fragment>
    <Dialog
        className='add_account_popup'
        fullScreen
        open={openPopup}
        onClose={handleClose}
      >
          <Toolbar style={{background:'darkred'}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {formTypes === 1 ? 'Add Car':'Update Car'}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
                <CloseIcon />
            </Button>
          </Toolbar>
        <List>
        <Container maxWidth="md" className='input_styling'>
      <Typography variant="h5" align="center" gutterBottom>
         Car Information
      </Typography>
      <form onSubmit={handleSubmit} >
        <Grid container spacing={2}>
        <Grid item xs={12}>
              {checkValidate.isvalidate &&
            <Alert severity={checkValidate.type} color={checkValidate.color}>
            {checkValidate.message}
            </Alert>}
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Plate Number *"
              name="car_number"
              variant="filled"
              value={formData.car_number}
              onChange={handleChanges}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Car Model *"
              name="car_model"
              variant="filled"
              value={formData.car_model}
              onChange={handleChanges}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Control Number *"
              name="ctr_num"
              variant="filled"
              value={formData.ctr_num}
              onChange={handleChanges}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
          <FormControl variant="filled" sx={{width:"100%"}}>
            <InputLabel id="demo-simple-select-standard-label" sx={{color:'white'}}>Select Drivers </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={formData.drivers}
              onChange={handleChanges}
              label="drivers"
              name="drivers"
              className='form_custom'
            >
              <MenuItem value="">
              <em>Select Options</em>
              </MenuItem>
              { datalist.map((x) =><MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}

            </Select>
          </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
          <FormControl variant="filled" sx={{width:"100%"}}>
            <InputLabel id="demo-simple-select-standard-label" sx={{color:'white'}}>Select Status * </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={formData.isActive}
              onChange={handleChanges}
              label="Active"
              name="isActive"
              className='form_custom'
            >
              <MenuItem value="">
              <em>Select Options</em>
              </MenuItem>
              <MenuItem value='1'>Active</MenuItem>
              <MenuItem value='0'>Not Active</MenuItem>
            </Select>
          </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="outlined" color="error">
              {formTypes === 1 ? 'Add Car':'Save Car' }
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
        </List>
      </Dialog>
    </Fragment>
      </Box>
    </Box>)


}

export default Car;
import {React,useEffect, useState,Fragment} from 'react';
import io from 'socket.io-client';
import Navigation from '../Menu/Nav';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Cookies from 'js-cookie';
import '../../App.css';
import Redirect from '../Redirect/Redirect';
import { GET_ACCOUNTS_ENDPOINT,CREATE_ACCOUNTS_ENDPOINT,RETRIEVE_ACCOUNTS_ENDPOINT,UPDATE_ACCOUNTS_ENDPOINT } from '../Endpoints';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Badge, Box, Toolbar, Typography, Dialog, List, ListItemButton, ListItemText, Divider,TextField, Button, Container, Grid,Avatar,InputLabel,FormControl,Alert } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


const drawerWidth = 240;

const Accounts = () => {

  const auth = Cookies.get('BookingToken');

  const [rows, setRows] = useState([]);
  const [openPopup, setopenPopup] = useState(false);
  const [formTypes, setformTypes] = useState();
  const [tempImage, setTempImage] = useState();
  const [editaccountID, seteditaccountID] = useState();
  const [iscounter,setIsCounter] = useState(false);

  const [checkValidate,setValidate] = useState({
    isvalidate:false
  });
  const [formData, setFormData] = useState({

    email: '',
    firstName: '',
    lastName: '',
    middleName: '',
    contacts: '',
    profileImage: '',
    roles:''
  });
  

   useEffect(() => {
    GetAccounts();
     }, []);

   const GetAccounts =  async () => {

    
    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }

    let response = await fetch(GET_ACCOUNTS_ENDPOINT(), { 
      method: "GET",
      headers: headersList
    });

    let data = await response.json();

    if(data.type===1){
      if (Array.isArray(data.message)) {
        setRows(data.message);
      } else {
        console.error('Invalid data format:', data.message);
      }
  
    }



 }

 function handleEditClick(id){
    setformTypes(2);
    GetAccountDetails(id)
    setopenPopup(true);
 }

 function HandleisCounter() {
   setIsCounter(!iscounter);
 }

 const handleClose = () =>{
  setopenPopup(false);
 }

const handleClickOpen = () => {
  setformTypes(1);
  setFormData(()=>{
    return {
        email: '',
        firstName: '',
        lastName: '',
        middleName: '',
        contacts: '',
        profileImage: '',
        roles:''
    }
  })
  setIsCounter(false);
  setTempImage('');
  setopenPopup(true);
};

const handleSubmit = (e) => {
  e.preventDefault();
  createAccountValidation();
  setTimeout(()=>{ 
    setValidate((prev)=>{
      return {
          isvalidate: false,
      }
    });
  },2000)

};


const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

const handleImageChange = (e) => {
  const file = e.target.files[0];
  setTempImage('');

  if (file) {

    if(!(file.type.startsWith('image/png') || file.type.startsWith('image/jpeg') || file.type.startsWith('image/jpg') )){

      setValidate((prev)=>{
        return {
            message:"Please select a valid PNG image.",
            type:"warning",
            isvalidate: true,
            color:'error'
        }
     })
     return;
    }

    if(file.size > 1024 * 1024) {

      setValidate((prev)=>{
        return {
            message:"Image size should be less than or equal to 1MB.",
            type:"warning",
            isvalidate: true,
            color:'error'
        }
       })
       return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData((prevData) => ({
        ...prevData,
        profileImage: reader.result,
      }));

      setValidate((prev)=>{
        return {
            isvalidate: false,
        }
      })
    };
    reader.readAsDataURL(file);

  }
};

const handleAvatarClick = () => {
  document.getElementById('profile-image-input').click();
};


const createAccountValidation = () => {

  if(formData.email === '' || formData.firstName === '' || formData.lastName === '' || formData.contacts === '' || formData.roles === ''){

  setValidate((prev)=>{
      return {
          message:"Please enter fill all empty fields!",
          type:"warning",
          isvalidate: true,
          color:'error'
      }
   })
   return;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(formData.email)) {

      setValidate((prev)=>{
          return {
              message:"Please enter a valid email address",
              type:"warning",
              isvalidate: true,
              color:'error'
          }
       })
       return;
  }

  if (!/^\d+$/.test(formData.contacts)) {

      setValidate((prev)=>{
          return {
              message:"Please enter a valid phone number",
              type:"warning",
              isvalidate: true,
              color:'error'
          }
       })
       return;
    }

    formTypes === 1 ?  CreateAccount():UpdateAccount();

  }


    
  const CreateAccount = async () => {

    let headersList = {
      "Accept": "*/*",
      "Authorization": "Bearer "+auth
     }
     const counter = iscounter === true ? 1: 0;

     let bodyContent = new FormData();
     bodyContent.append("profile_img",formData.profileImage);
     bodyContent.append("fname",formData.firstName);
     bodyContent.append("lname",formData.lastName);
     bodyContent.append("mname",formData.middleName);
     bodyContent.append("email",formData.email);
     bodyContent.append("contact",formData.contacts);
     bodyContent.append("roles",formData.roles);
     bodyContent.append("is_counter",counter);
     bodyContent.append("verfied",1);

     let response = await fetch(CREATE_ACCOUNTS_ENDPOINT(), { 
       method: "POST",
       body: bodyContent,
       headers: headersList
     });

     let data = await response.json();
     if(data.type == 1){  
        setFormData(()=>{
            return {
                email: '',
                firstName: '',
                lastName: '',
                middleName: '',
                contacts: '',
                profileImage: '',
                roles:''
            }
        })
        setValidate((prev)=>{
          return {
              message:"Record added successfully",
              type:"success",
              isvalidate: true,
              color:'success'
          }
       })

       GetAccounts();
       setTimeout(()=>{ 
        setopenPopup(false);
        setValidate((prev)=>{
          return {
              isvalidate: false,
          }
        });
      },1000)
       
      }else{
        setValidate((prev)=>{
          return {
              message:data.message,
              type:"warning",
              isvalidate: true,
              color:'error'
          }
       });
      }

    }


    const UpdateAccount = async () => {

      let headersList = {
        "Accept": "*/*",
        "Authorization": "Bearer "+auth
       }

       const profiles = tempImage ==='' ? formData.profileImage:'';
       const counter = iscounter === true ? 1: 0;
  
       let bodyContent = new FormData();
       bodyContent.append("profile_img",profiles);
       bodyContent.append("fname",formData.firstName);
       bodyContent.append("lname",formData.lastName);
       bodyContent.append("mname",formData.middleName);
       bodyContent.append("email",formData.email);
       bodyContent.append("contact",formData.contacts);
       bodyContent.append("roles",formData.roles);
       bodyContent.append("account_id",editaccountID);
       bodyContent.append("is_counter",counter);

  
       let response = await fetch(UPDATE_ACCOUNTS_ENDPOINT(), { 
         method: "POST",
         body: bodyContent,
         headers: headersList
       });
  
       let data = await response.json();
       if(data.type == 1){
        
          setValidate((prev)=>{
            return {
                message:"Record update successfully",
                type:"success",
                isvalidate: true,
                color:'success'
            }
         })
  
         GetAccounts();
         setTimeout(()=>{ 
          setopenPopup(false);
          setValidate((prev)=>{
            return {
                isvalidate: false,
            }
          });
        },1000)
         
        }else{
          setValidate((prev)=>{
            return {
                message:data.message,
                type:"warning",
                isvalidate: true,
                color:'error'
            }
         });
        }
  
    }


    const GetAccountDetails = async (account_id) => {


      let headersList = {
        "Accept": "*/*",
        "Authorization": "Bearer "+auth
       }
       let bodyContent = new FormData();
       bodyContent.append("account_id",account_id);
  
       let response = await fetch(RETRIEVE_ACCOUNTS_ENDPOINT(), { 
         method: "POST",
         body: bodyContent,
         headers: headersList
       });
  
       let data = await response.json();

       if(data.type == 1){  
          setFormData(()=>{
              return {
                  email: data.message.email,
                  firstName: data.message.first_name,
                  lastName: data.message.last_name,
                  middleName: data.message.middle_name,
                  contacts:data.message.phone_number,
                  roles:data.message.roles
              }
          })
          setIsCounter(data.message.is_counter);
          
          seteditaccountID(account_id);
          setTempImage(data.message.profileImage);
        }

      }





  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 120 },
    { field: 'first_name', headerName: 'First Name', width: 150 },
    { field: 'last_name', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'contact', headerName: 'Contacts#', width: 200 },
    { field: 'user_role', headerName: 'Roles', width: 80, renderCell: (params) => (
      <Badge badgeContent= {params.value} color="success" style={{width: '20px'}} />
    ) },
    {
      field: 'editButton',
      headerName: 'Update',
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleEditClick(params.row.id)} 
          color="error" 
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];


  return (

    <Box sx={{
    display: 'flex',
    background: '#222',
    color:'white',
    height: '100vh',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',  // For Firefox
    scrollbarColor: '#333 #222',  // For Firefox
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#333',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#222',
    },}}>
      <Redirect path_name="accounts"/>
      <Navigation page_name="Accounts"/>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
      <Toolbar />
     <Button className='btn_open' variant="outlined" color="error" onClick={handleClickOpen} ><AddCircleIcon color='error' sx={{marginRight: '10px'}} /> Add Account</Button>
    <div className='account_table'>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20]}

      />
    </div>

    <Fragment>
    <Dialog
        className='add_account_popup'
        fullScreen
        open={openPopup}
        onClose={handleClose}
      >
          <Toolbar style={{background:'darkred'}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {formTypes === 1 ? 'Add Account':'Update Account'}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
                <CloseIcon />
            </Button>
          </Toolbar>
        <List>
        <Container maxWidth="md" className='input_styling'>
      <Typography variant="h5" align="center" gutterBottom>
        Account Information
      </Typography>
      <form onSubmit={handleSubmit} >
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="profile-image-input"
              type="file"
              name='profile-image-input'
              onChange={handleImageChange}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              onClick={handleAvatarClick}
              style={{ cursor: 'pointer' }}
            >
              <Avatar
                alt="Profile Image"
                src={tempImage ===''? formData.profileImage: tempImage}
                sx={{
                  width: 100,
                  height: 100,
                  backgroundColor: '#333',
                  border: '2px solid #ccc', 
                  borderRadius: '50%', 
                }}
              />
              <Typography variant="caption" component="div">
                Profile Image
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
              {checkValidate.isvalidate &&
            <Alert severity={checkValidate.type} color={checkValidate.color}>
            {checkValidate.message}
            </Alert>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email *"
              name="email"
              variant="filled"
              value={formData.email}
              onChange={handleChange}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name*"
              name="firstName"
              variant="filled"
              value={formData.firstName}
              onChange={handleChange}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="filled"
              label="Last Name*"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="filled"
              label="Middle Name (Optional)"
              name="middleName"
              value={formData.middleName}
              onChange={handleChange}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
              
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="filled"
              label="Contacts*"
              name="contacts"
              value={formData.contacts}
              onChange={handleChange}
              className='form_custom'
              InputLabelProps={{
                    style: { color: 'white' },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl variant="filled" sx={{width:"100%"}}>
            <InputLabel id="demo-simple-select-standard-label" sx={{color:'white'}}>Select Role</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={formData.roles}
              onChange={handleChange}
              label="roles"
              name="roles"
              className='form_custom'
            >
              <MenuItem value="">
              <em>Select Role</em>
              </MenuItem>
              <MenuItem value={0}>User</MenuItem>
              <MenuItem value={1}>Driver</MenuItem>
              <MenuItem value={2}>Admin</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12}>
          <FormControlLabel
          control={
            <Switch
              color="warning"
              className='List_titles'
              checked={iscounter}
              onChange = {HandleisCounter}
            />
         }
          label="is Counter"
          style={{color:'white'}}
          />
           </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="outlined" color="error">
              {formTypes === 1 ? 'Add Account':'Save Update' }
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
        </List>
      </Dialog>
    </Fragment>
        
    </Box>
    </Box>
    )


}

export default Accounts;
import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import ListItemMenu from '../Menu/menu';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Logo from '../../assi_white_logo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import './../../App.css';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Cookies from 'js-cookie';

//import Cookies from 'js-cookie';

const drawerWidth = 240;
const myObject: Props = { window: () => window };


const Navigation = (props) => {
  

  const navigate = useNavigate();

  const { window } = myObject;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const HandleNavigation =  (pagename) => {
        navigate('/'+pagename);
  }

  const Logout = () => {

    Cookies.remove('BookingToken');
    Cookies.remove('uid');

    navigate('/');
  }

  
  const drawer = (
    <div className='sidenav_styling'>
    <Toolbar >
        <Avatar
            alt="Admin Panel"
            src={Logo}
            sx={{ width: 130, height: 130, mx: 'auto', my: 2 }}
        />
    </Toolbar>
      <Divider color="#333" />
      <List>
        {ListItemMenu.map((text, index) => ( 
        <ListItem key={index} disablePadding className='list_icons' style={{
        background: props.page_name === text.title ? '#333' : 'transparent',
        }}>
            <ListItemButton onClick={()=>{
                HandleNavigation(text.path)
            }}>
              <ListItemIcon sx={{color:'white'}}>
                {text.icons}
              </ListItemIcon>
              <ListItemText className='List_titles'  primary={text.title} />
            </ListItemButton>
          </ListItem>
        ))}

        <ListItem disablePadding className='list_icons'>
            <ListItemButton onClick={Logout}>
              <ListItemIcon sx={{color:'white'}}>
               <LogoutIcon/>
              </ListItemIcon>
              <ListItemText  className='List_titles'  primary='Logout' />
            </ListItemButton>
          </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

return (
<div>
<CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
      <Toolbar sx={{ background: 'Darkred', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography className='List_titles' variant="h6" noWrap component="div">
            {props.page_name}
          </Typography>
        </div>
        <FormControlLabel
        control={
          <Switch
            onChange={() => props.onSwitch && props.onSwitch()}
            color="warning"
            className='List_titles'
            checked={Cookies.get('Push') === 'true'}

          />
        }
        label="Push"
        />
      </Toolbar>
      </AppBar>

<Box
component="nav"
sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }}}
aria-label="mailbox folders"
>
{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
<Drawer
  container={container}
  variant="temporary"
  open={mobileOpen}
  onClose={handleDrawerToggle}
  ModalProps={{
    keepMounted: true, // Better open performance on mobile.
  }}
  sx={{
    display: { xs: 'block', sm: 'none' },
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
    
  }}
>
  {drawer}
</Drawer>
<Drawer
  variant="permanent"
  sx={{
    display: { xs: 'none', sm: 'block' },
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
  }}
  open
>
  {drawer}
</Drawer>
</Box>
</div>
)
}

export default Navigation;
const BarOptions = {
         responsive: true,
         maintainAspectRatio: false,
         scales: {
          x: {
            ticks: {
              color: 'white',
              font: {
                family: 'Nunito',
              },
            },
          },
          y: {
            ticks: {
              color: 'white',
              font: {
                family: 'Nunito',
              },
            },
          },
        },
        plugins: {
            legend: {
              display: false, 
            },
            title: {
              custom: true, 
              display: true, 
              text: 'MTD Terminals', 
              color: 'white',
              font: {
                family: 'Nunito',
              },
            },
          },

}


const DoughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
     x: {
       ticks: {
         color: 'white',
         font: {
           family: 'Nunito',
         },
       },
     },
     y: {
       ticks: {
         color: 'white',
         font: {
           family: 'Nunito',
         },
       },
     },
   },
   plugins: {
     legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: 'red',
          font: {
            family: 'Nunito',
          },
          usePointStyle: true, // Use circles instead of rectangles
        },
      },
       title: {
         custom: true, 
         display: true, 
         text: 'MTD Accounts', 
         color: 'white',
         font: {
           family: 'Nunito',
         },
       },
     },

}


const LineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
     x: {
       ticks: {
         color: 'white',
         font: {
           family: 'Nunito',
         },
       },
     },
     y: {
       ticks: {
         color: 'white',
         font: {
           family: 'Nunito',
         },
       },
     },
   },
   plugins: {
     legend: {
        display: false,
        position: 'bottom',
        labels: {
          color: 'red',
          font: {
            family: 'Nunito',
          },
          usePointStyle: false, // Use circles instead of rectangles
        },
      },
       title: {
         custom: true, 
         display: true, 
         text: 'MTD Bookings', 
         color: 'white',
         font: {
           family: 'Nunito',
         },
       },
     },

}







export {BarOptions,DoughnutOptions,LineOptions}
import React, { useState,useEffect,  } from "react";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
//import Cookies from 'js-cookie';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import logo from '../assi_white_logo.png';
import Grid from '@mui/material/Grid';
import { LOGIN_ENDPOINT } from "./Endpoints";
import '../App.css';
import Cookies from 'js-cookie';

const Login = () =>{

    const navigate = useNavigate();

    const [InputFields,SetInput] = useState({
        email:"",
        password:""
    })

    const [checkValidate,setValidate] = useState({
        isvalidate:false
    });


    useEffect(() => {
        const session = Cookies.get('BookingToken');
        if (session) {
            //navigate('/dashboard');
            navigate(-1);
        } else {
            navigate('/');

        }
      }, []);

    const HandleInputs = (e) => {
         const {name,value} = e.target;
         SetInput(prev=>{
            return {
                ...prev,
                [name]:value
            }
         })

    }


    const LoginValidation = () => {

        if(InputFields.email === '' || InputFields.password === ''){

        setValidate((prev)=>{
            return {
                message:"Please enter email and password!",
                type:"warning",
                isvalidate: true,
                color:'error'
            }
         })
         return;
        }
        
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(InputFields.email)) {

            setValidate((prev)=>{
                return {
                    message:"Please enter a valid email address",
                    type:"warning",
                    isvalidate: true,
                    color:'error'
                }
             })
            
             return;

        }

        Request();
    }

    const HandleLogin = (e) => {
        LoginValidation();
        e.preventDefault();
       
    }


    const Request = async () => {

          let headersList = {
            "Accept": "*/*",
           }
           const mySession = localStorage.getItem('BookingToken');

           
           let bodyContent = new FormData();
           bodyContent.append("email",InputFields.email );
           bodyContent.append("password", InputFields.password);
           
           let response = await fetch(LOGIN_ENDPOINT(), { 
             method: "POST",
             body: bodyContent,
             headers: headersList
           });
           
           let data = await response.json();

           if(data.type == 1){

            SetInput((prev)=>{
                return {
                    email:"",
                    password:""
                }
             })

             setValidate((prev)=>{
                return {
                    message:"Login Successfull",
                    type:"success",
                    isvalidate: true,
                    color:'success'
                }
             })

            Cookies.set('BookingToken', data.message.token, { expires: 1 }); 
            Cookies.set('uid', data.message.uid, { expires: 1 });

             //localStorage.setItem('BookingToken',  data.message.token);
            // localStorage.setItem('uid', data.message.uid);
             
            const mySession = Cookies.get('BookingToken');
            // const uid = localStorage.getItem('uid');

             if (mySession) {
                navigate('/dashboard');
             }

             /*
             if (mySession && uid) {
        
                //window.location.reload();
              }
            const intervalId = setInterval(() => {

            }, 1000);*/
            

              
           }else{

             setValidate((prev)=>{
                return {
                    message:data.message,
                    type:"warning",
                    isvalidate: true,
                    color:'error'
                }
             })
           }


    }


    return (
        <Grid container>
          <Grid item xs={1} lg={4}></Grid>
          <Grid item xs={10} lg={4}>
            <form onSubmit={HandleLogin}>
              <Stack
                className="Login_Container"
                spacing={2}
                noValidate
                autoComplete="off"
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Avatar
                    src={logo}
                    sx={{ width: 150, height: 150 }}
                  />
                </div>
                {checkValidate.isvalidate &&
                  <Alert severity={checkValidate.type} color={checkValidate.color}>
                    {checkValidate.message}
                  </Alert>}
    
                <TextField
                  name="email"
                  hiddenLabel
                  placeholder="Email..."
                  variant="filled"
                  size="small"
                  onChange={HandleInputs}
                  value={InputFields.email}
                  type="text"
                  InputProps={{
                  style: { borderBottom: '1px solid rgba(255,255,255,0.3)', color: 'white' },
                 }}
                 InputLabelProps={{
                    style: { color: 'white' },
                 }}
                 autoComplete="off"
                />
                <TextField
                  name="password"
                  hiddenLabel
                  placeholder="Password..."
                  variant="filled"
                  size="small"
                  onChange={HandleInputs}
                  value={InputFields.password}
                  type="password"
                  InputProps={{
                  style: { borderBottom: '1px solid rgba(255,255,255,0.3)', color: 'white' },
                 }}
                 InputLabelProps={{
                    style: { color: 'white' },
                 }}
                 autoComplete="off"
                  
                />
                <Button className="login_button" variant="outlined" type="submit">
                  Login
                </Button>
              </Stack>
            </form>
          </Grid>
          <Grid item xs={1} lg={4}></Grid>
        </Grid>
      );
}

export default Login;